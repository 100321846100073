var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"violation-toggler__wrapper"},[_c('div',{class:{
            'violation-toggler': true,
            'violation-toggler_accept': _vm.isStatusAccept,
            'violation-toggler_done': _vm.isStatusDone,
        },on:{"click":_vm.onToggleButton}},[(!_vm.isAcceptanceCreate)?_c('div',{staticClass:"violation-toggler__back-button"},[(_vm.isViolationDisplay)?_c('KeyboardBackspaceSVG'):_c('div',[_vm._v(" "+_vm._s(_vm.indexPoint + 1)+" ")])],1):_vm._e(),_c('div',{class:{
                'violation-toggler__title': true,
                'violation-toggler__title--left': _vm.isAcceptance && !_vm.isAcceptanceCreate,
            }},[_vm._t("default")],2),(_vm.isCreateViolation && !_vm.isViolationDisplay && !_vm.isAcceptanceCreate)?_c('div',{staticClass:"violation-toggler__delete-button"},[_c('TrashSVG',{staticClass:"violation-toggler__delete-button-icon",on:{"click":function($event){$event.stopPropagation();return _vm.showDeleteViolationModal.apply(null, arguments)}}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }