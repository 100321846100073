var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VGridRow',{staticClass:"reject-toggler grid-row__shutdown_padding-x"},[_c('VGridCol',{staticClass:"reject-toggler__title grid-col_center",on:{"click":_vm.onToggleViewCauses}},[_vm._v(" "+_vm._s(_vm.$t('page.typeForm.causes'))+" "),_c('ChevronSVG',{class:{
                'reject-toggler-title__image': true,
                'reject-toggler-title__image_open': _vm.isViewCauses,
            }})],1),_c('VGridCol',{ref:"refRejectToggler",class:{
            'reject-toggler__area': true,
            'reject-toggler__area_open': _vm.isViewCauses,
        }},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }