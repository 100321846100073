var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"violation-form-title"},[_vm._v(" "+_vm._s(_vm.$t('components.violation.formTitleAcceptanceReport'))+" ")]),_c('div',{staticClass:"violation-form violation-form_margin-bottom",class:{ 'violation-form-disabled': _vm.remarkCreated?.statusId !== _vm.StatusEnum.InWork || !_vm.hasCompleteRight }},[_c('VGridRow',{staticClass:"grid-row_justify-center grid-row__shutdown_padding-x violation-form__component_margin-bottom"},[_c('VGridCol',{staticClass:"grid-col_justify-center violation-form__label",attrs:{"md-4":"","sm-3":"","xs-12":""}},[_vm._v(" "+_vm._s(_vm.$t('page.violationForm.generalContractor'))+" ")]),_c('VGridCol',{attrs:{"md-8":"","sm-9":"","xs-12":""}},[_c('VInput',{staticClass:"create-violation__input acceptance-disabled",attrs:{"disabled":"","type":"text"},model:{value:(_vm.remarkCreated.generalContractor),callback:function ($$v) {_vm.$set(_vm.remarkCreated, "generalContractor", $$v)},expression:"remarkCreated.generalContractor"}})],1)],1),(_vm.subcontractorList.length)?_c('VGridRow',{staticClass:"grid-row_justify-center grid-row__shutdown_padding-x violation-form__component_margin-bottom"},[_c('VGridCol',{staticClass:"grid-col_justify-center violation-form__label",attrs:{"md-4":"","sm-3":"","xs-12":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('page.violationForm.subcontractor')))])]),_c('VGridCol',{attrs:{"md-8":"","sm-9":"","xs-12":""}},[_c('VMultiselect',{ref:"refSelectors",class:{
                        'acceptance-disabled': !(
                            _vm.remarkCreated?.statusId !== _vm.StatusEnum.InWork || !_vm.hasCompleteRight
                        ),
                    },attrs:{"disabled":_vm.remarkCreated?.statusId !== _vm.StatusEnum.InWork || !_vm.hasCompleteRight,"options":_vm.subcontractorList,"placeholder":_vm.$t('page.violationForm.setTypeOfRemarks'),"multiple":false,"label":"shortName","track-by":"id"},on:{"input":_vm.updateSubcontractor},model:{value:(_vm.subcontractor),callback:function ($$v) {_vm.subcontractor=$$v},expression:"subcontractor"}},[_c('template',{slot:"noOptions"},[_vm._v(" "+_vm._s(_vm.$t('page.multiselectorSlot.noDataAvailable'))+" ")]),_c('template',{slot:"noResult"},[_vm._v(" "+_vm._s(_vm.$t('page.multiselectorSlot.elementByRequestNotFound'))+" ")])],2)],1)],1):_vm._e(),(_vm.executorList.length)?_c('VGridRow',{staticClass:"grid-row_justify-center grid-row__shutdown_padding-x violation-form__component_margin-bottom"},[_c('VGridCol',{staticClass:"grid-col_justify-center violation-form__label",attrs:{"md-4":"","sm-3":"","xs-12":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('page.violationForm.executor')))])]),_c('VGridCol',{attrs:{"md-8":"","sm-9":"","xs-12":""}},[_c('VMultiselect',{ref:"refSelectors",class:{
                        'acceptance-disabled': !(
                            _vm.remarkCreated?.statusId !== _vm.StatusEnum.InWork || !_vm.hasCompleteRight
                        ),
                    },attrs:{"disabled":_vm.remarkCreated?.statusId !== _vm.StatusEnum.InWork || !_vm.hasCompleteRight,"options":_vm.executorList,"placeholder":_vm.$t('page.violationForm.setTypeOfRemarks'),"multiple":false,"label":"name","track-by":"id"},on:{"input":_vm.updateExecutors},model:{value:(_vm.executor),callback:function ($$v) {_vm.executor=$$v},expression:"executor"}},[_c('template',{slot:"noOptions"},[_vm._v(" "+_vm._s(_vm.$t('page.multiselectorSlot.noDataAvailable'))+" ")]),_c('template',{slot:"noResult"},[_vm._v(" "+_vm._s(_vm.$t('page.multiselectorSlot.elementByRequestNotFound'))+" ")])],2)],1)],1):_vm._e(),_c('VGridRow',{staticClass:"grid-row_justify-center grid-row__shutdown_padding-x violation-form__component_margin-bottom"},[_c('VGridCol',{staticClass:"grid-col_justify-center violation-form__label",attrs:{"md-4":"","sm-3":"","xs-12":""}},[_vm._v(" "+_vm._s(_vm.$t('page.violationForm.statusOfRemarks'))+" ")]),_c('VGridCol',{attrs:{"md-8":"","sm-9":"","xs-12":""}},[_c('VInput',{staticClass:"create-violation__input acceptance-disabled",class:{
                        'create-violation__input--in-work': _vm.remarkCreated?.statusId === _vm.StatusEnum.InWork,
                        'create-violation__input--done': _vm.remarkCreated?.statusId === _vm.StatusEnum.Done,
                        'create-violation__input--accept': _vm.remarkCreated?.statusId === _vm.StatusEnum.Accept,
                        'create-violation__input--cancelled':
                            _vm.remarkCreated?.statusId === _vm.StatusEnum.Cancelled ||
                            _vm.remarkCreated?.statusId === _vm.StatusEnum.Rejected,
                    },attrs:{"placeholder":_vm.$t('page.typeSelector.placeholder'),"disabled":"","type":"text"},model:{value:(_vm.remarkStatus),callback:function ($$v) {_vm.remarkStatus=$$v},expression:"remarkStatus"}})],1)],1),(_vm.remarkCreated?.statusId === _vm.StatusEnum.InWork && _vm.hasCompleteRight)?_c('VGridRow',{staticClass:"grid-row_justify-center grid-row__shutdown_padding-x violation-form__component_margin-bottom"},[_c('VGridCol',{staticClass:"grid-col_justify-center violation-form__label",attrs:{"md-4":"","sm-3":"","xs-12":""}}),_c('VGridCol',{attrs:{"md-8":"","sm-9":"","xs-12":""}},[_c('VButtonBig',{staticClass:"remark-done-button",attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.resolveRemark.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('page.violationForm.remarkDone'))+" ")])],1)],1):_vm._e(),_c('VGridRow',{staticClass:"grid-row__shutdown_padding-x violation-form__component_margin-bottom"},[_c('VGridCol',{staticClass:"violation-form__label",attrs:{"md-4":"","sm-3":"","xs-12":""}},[_c('span',{staticClass:"required-field"},[_vm._v(_vm._s(_vm.$t('page.violation.comment')))])]),_c('VGridCol',{staticClass:"grid-col_justify-center",attrs:{"md-8":"","sm-9":"","xs-12":""}},[(!(_vm.remarkCreated?.statusId !== _vm.StatusEnum.InWork || !_vm.hasCompleteRight))?_c('VTextarea',{attrs:{"error":_vm.isContentError,"max":250,"placeholder":String(_vm.$t('page.reportComment.placeholder'))},on:{"input":_vm.resetError},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"violation-form__description acceptance-disabled",attrs:{"rows":"5","disabled":""},domProps:{"value":(_vm.content)},on:{"input":function($event){if($event.target.composing)return;_vm.content=$event.target.value}}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }