var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VGridCol',{staticClass:"create-violation__fieldset type-and-status"},[(_vm.violation?.statusId)?_c('ManagerSelectorsInput',{class:{
            'type-and-status__status--red': [_vm.StatusEnum.InWork, _vm.StatusEnum.Cancelled, _vm.StatusEnum.Rejected].includes(
                _vm.violation?.statusId,
            ),
            'type-and-status__status--yellow': [_vm.StatusEnum.Done, _vm.StatusEnum.Approval].includes(_vm.violation?.statusId),
            'type-and-status__status--green': _vm.violation?.statusId === _vm.StatusEnum.Accept,
            'type-and-status__status--purple': _vm.violation?.statusId === _vm.StatusEnum.Created,
        },attrs:{"data":_vm.getStatus(_vm.violation?.statusId)},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Статус")]},proxy:true},{key:"error",fn:function(){return [_vm._v("-")]},proxy:true}],null,false,1747075119)}):_vm._e(),_c('ManagerSelectorsInput',{attrs:{"data":"Строительное предписание"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Тип предписания")]},proxy:true},{key:"error",fn:function(){return [_vm._v("-")]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }