import { render, staticRenderFns } from "./SelectorScheme.vue?vue&type=template&id=05b8952c&"
import script from "./SelectorScheme.vue?vue&type=script&lang=ts&"
export * from "./SelectorScheme.vue?vue&type=script&lang=ts&"
import style0 from "./SelectorScheme.vue?vue&type=style&index=0&id=05b8952c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports